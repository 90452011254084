import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Footer() {
    const domain = window.location.origin;
    const location = useLocation();
    const currentUrl = encodeURIComponent(`${domain}${location.pathname}`);

    function handleSiteSpecLinkClick() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <footer>
            <nav>
                <ul>
                    <li><a href={`https://pagespeed.web.dev/report?url=${currentUrl}&form_factor=desktop`} target="_blank" rel="noopener noreferrer">Fast PageSpeed</a></li>
                    <li><a href={`https://validator.w3.org/nu/?doc=${currentUrl}`} target="_blank" rel="noopener noreferrer">Valid HTML</a></li>
                    <li><Link to="/specification-document" onClick={handleSiteSpecLinkClick}>Site Specs</Link></li>
                </ul>
            </nav>
            
            <p className='copyright'>Copyright © {new Date().getFullYear()}</p>
        </footer>
    )
}

export default Footer