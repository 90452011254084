import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import SkipLink from '../components/SkipLink';
import logo from '../images/logo.svg';

const Header = ({headerHeight}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        document.body.classList.toggle('menu-open', isMenuOpen);
        return () => {
            document.body.classList.remove('menu-open');
        };
    }, [isMenuOpen]);

    return (
        <header id="header" style={{ "--header-height": headerHeight + 'px' }}>
            <span className='header-left'>
                <SkipLink />
                <img src={logo} alt="logo" width="30" height="30" />
            </span>
            <nav>
                <button className={`burger ${isMenuOpen ? 'open' : ''}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <span className="bar1"></span>
                    <span className="bar2"></span>
                    <span className="bar3"></span>
                    <span className="bar4"></span>
                    <span className='visuallyHidden'>Main Menu</span>
                </button>
                <ul className={`menu ${isMenuOpen ? 'open' : ''}`}>
                    <li>
                        <Link to="/" onClick={() => setIsMenuOpen(false)}>Home</Link>
                    </li>
                    <li>
                        <Link to="/experience" onClick={() => setIsMenuOpen(false)}>Experience</Link>
                    </li>
                    {/* <li>
                        <Link to="/three-js" onClick={() => setIsMenuOpen(false)}>Three.js</Link>
                    </li> */}
                    <li>
                        <Link to="/contact" onClick={() => setIsMenuOpen(false)}>Contact</Link>
                    </li>
                </ul>
            </nav>
        </header>
    )
};

export default Header;
