import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

const Home = lazy(() => import('./Home'));
const ThreeJS = lazy(() => import('./ThreeJS'));
const Experience = lazy(() => import('./Experience'));
const SpecificationDocument = lazy(() => import('./SpecificationDocument'));
const Contact = lazy(() => import('./Contact'));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    const header = document.getElementsByTagName('header')[0];
    const footer = document.getElementsByTagName('footer')[0];
    setHeaderHeight(header.offsetHeight);
    setFooterHeight(footer.offsetHeight);

    const handleResize = () => {
      setHeaderHeight(header.offsetHeight);
      setFooterHeight(footer.offsetHeight);
    };

    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <BrowserRouter>
      <Header headerHeight={headerHeight} />
        <div className="container" style={{ minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)` }}>
          <div className="main-content">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/experience" element={<Experience />} />
                <Route path="/three-js" element={<ThreeJS />} />
                <Route path="/specification-document" element={<SpecificationDocument />} />
                <Route path="/contact" element={<Contact />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
